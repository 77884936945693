body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */
.react-time-picker__inputGroup__input,
.react-time-picker__inputGroup__hour {
  width: 18px !important;
}

.react-time-picker__wrapper {
  padding: 10px;
  width: 200px !important;
}

.react-time-picker__button {
  background-color: #15178e !important;
}

.react-time-picker__clear-button__icon,
.react-time-picker__button__icon {

  stroke: white !important;
}

a,
a:visited {
  text-decoration: none;
  /* Removes underline from links */
  color: inherit;
  /* Keeps the text color the same as its parent */
}

.css-15y2tf8-MuiDataGrid-root {

  border: none !important;
}

.css-nmr3ai-MuiDataGrid-root {
  border-style: none !important
}

::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Handle color */
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
  /* Handle color on hover */
}

::-webkit-scrollbar-button {
  display: none;
  /* Hide scrollbar arrows */
}

.css-3wmoku-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;

}

.css-1q9w4iy-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;

}


body,
html {
  overflow-y: hidden;
  overflow-x: hidden;

  /* Hide vertical scrollbar */
  height: 100%;
  /* Prevent extra space */
}

/* For Firefox */
/* * {
  scrollbar-width: thin;
  /* "auto" or "thin" */
/* scrollbar-color: #888 #f1f1f1; */
/* Handle and track color */
/* } */